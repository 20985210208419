export const CATEGORIES = [
  {
    value: '509660',
    text: 'Art',
    box_art_url: 'https://static-cdn.jtvnw.net/ttv-boxart/509660-{width}x{height}.jpg',
  },
  {
    value: '509658',
    text: 'Just Chatting',
    box_art_url: 'https://static-cdn.jtvnw.net/ttv-boxart/509658-{width}x{height}.jpg',
  },
  {
    value: '518203',
    text: 'Sports',
    box_art_url: 'https://static-cdn.jtvnw.net/ttv-boxart/518203-{width}x{height}.jpg',
  },
  {
    value: '515214',
    text: 'Politics',
    box_art_url: 'https://static-cdn.jtvnw.net/ttv-boxart/515214-{width}x{height}.jpg',
  },
  {
    value: '26936',
    text: 'Music',
    box_art_url: 'https://static-cdn.jtvnw.net/ttv-boxart/26936-{width}x{height}.jpg',
  },
  {
    value: '509671',
    text: 'Fitness & Health',
    box_art_url: 'https://static-cdn.jtvnw.net/ttv-boxart/509671-{width}x{height}.jpg',
  },
  {
    value: '509667',
    text: 'Food & Drink',
    box_art_url: 'https://static-cdn.jtvnw.net/ttv-boxart/509667-{width}x{height}.jpg',
  },
  {
    value: '417752',
    text: 'Talk Shows & Podcasts',
    box_art_url: 'https://static-cdn.jtvnw.net/ttv-boxart/417752-{width}x{height}.jpg',
  },
];

