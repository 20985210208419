<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <span
        v-if="triggerElementType === 'span'"
        class="add-destination"
        v-bind="attrs"
        v-on="on"
        :data-test-id="`dsh-dst-add-destination-${triggerElementType}`"
      >
        {{ $t('destinations.addADestination') }}
        <span class="ml-3">+</span>
      </span>
      <v-btn
        v-if="triggerElementType === 'icon-button'"
        data-test-id="broadcast-add-button"
        class="ma-2"
        width="36"
        height="36"
        outlined
        fab
        dark
        color="var(--v-primary-lighten4)"
        v-bind="attrs"
        v-on="on"
      >
        <AppIcon size="16" icon="plus" />
      </v-btn>
      <v-btn v-if="triggerElementType === 'button'" class="add-destination-btn" dark v-bind="attrs" v-on="on">
        <span class="mr-1" :class="{ 'opacity-50': !bright }">+</span>
        <span :class="{ 'opacity-50': !bright }">{{ $t('destinations.addADestination') }}</span>
      </v-btn>
    </template>
    <v-list class="py-0">
      <v-list-item v-for="(item, index) in destinationItems" :key="index" class="select-destination" @click="goProviderUrl(item)">
        <v-list-item-title>
          <div class="d-flex align-center">
            <ProviderIcon :provider="item" class="mr-4" :alt="item" :data-test-id="`dsh-dst-add-${item}`" style="width: 24px" />
            <span>{{ item }}</span>
          </div>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import { mapActions } from 'vuex';
import XssService from '@/services/xss.service';
import { consoleError } from 'xpermeet-lib';
import { DESTINATION_TYPES } from '@/constants/enums';
import { createATagAndClick } from '@/helpers/create-element';
import { TOAST_TYPE } from '@/constants/toast';
import { NOTIFICATION } from '@/constants/modules';

export default {
  name: 'AddDestination',
  props: {
    triggerElementType: {
      type: String,
      default: 'span',
    },
    bright: Boolean,
  },
  data() {
    return {
      destinationItems: [DESTINATION_TYPES.YOUTUBE, DESTINATION_TYPES.TWITCH],
    };
  },
  created() {
    if (this.$route.query?.errorCode) {
      this.showToastNotification({ body: this.$t(`error.destination.${this.$route.query?.errorCode}`), config: { type: TOAST_TYPE.WARNING } });
      this.$router.replace({});
    }
  },
  methods: {
    ...mapActions(NOTIFICATION, ['showToastNotification']),
    createATagAndClick,
    async goProviderUrl(value) {
      try {
        const { location } = window;
        const { origin, pathname } = location;
        const url = `${process.env.VUE_APP_PROVIDER_URL}/api/provider/${value}/auth-url?redirectUri=${origin}${pathname}`;
        const res = await XssService.addDestination(url);
        createATagAndClick(res.data);
      } catch (error) {
        consoleError(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.add-destination-btn {
  // display: none;
  border-radius: 8px;
  ::v-deep.v-btn__content {
    text-transform: none;
    font-size: 11px;
    font-weight: 400;
  }
  &.night-rider {
    color: var(--v-text-gray-base);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  }
}
</style>

