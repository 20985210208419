var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-alert",
    {
      staticStyle: { margin: "0" },
      attrs: { type: "warning", dense: "", text: "", prominent: "" },
    },
    [
      _c("div", [_vm._v(_vm._s(_vm.$t("providersTokenExpired")))]),
      _c(
        "div",
        {
          staticClass: "text-decoration-underline cursor-pointer",
          on: { click: _vm.goToDestinationPage },
        },
        [_vm._v(_vm._s(_vm.$t("destinations.page")))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }