<template>
  <AppModal
    v-model="dialog"
    :show-back-button="!!selectedForDelete"
    :title="$t('broadcasts.broadcastTo')"
    :max-width="!!selectedForDelete ? '450' : '650'"
    @close="close"
    @back="selectedForDelete = null"
  >
    <ExpiredTokenWarningVue v-if="containExpiredToken" @clicked="close" class="mb-2" />

    <div v-if="!selectedForDelete">
      <div v-if="filteredDestinations.length">
        <div>
          <AppButton
            v-for="(destination, key) in filteredDestinations"
            class="add-remove-destination"
            :key="key"
            :data-test-id="`brd-brd-add-${key}`"
            :disabled="!destination.tokenIsValid"
            :class="{
              'ml-2': key !== 0,
              'opacity-50': !selectedDestinations.includes(destination.id),
              active: selectedDestination.id === destination.id,
            }"
            @click="selectDestination(destination)"
          >
            <ProviderIcon :provider="destination.channel" class="mr-4" alt="Youtube" style="width: 24px" />
            <div>
              <div :class="{ 'opacity-50': !selectedDestinations.includes(destination.id) }">{{ destination.user }}</div>
              <div v-if="!destination.tokenIsValid" class="warning-text">
                {{ $t('tokenExpired') }}
              </div>
            </div>
          </AppButton>
          <AddDestination v-if="!isLiveSession" trigger-element-type="icon-button" />
        </div>
        <div v-if="selectedDestination.user">
          <div>{{ $t('selected') }}: {{ selectedDestination.user }}</div>
          <div v-if="errors.length" class="errors py-2">
            <div class="error-title">
              {{ $t('error.errors') }}
            </div>
            <div v-for="(error, key) in errors" :key="key">
              <span class="type">{{ error.type }} :</span>
              <span class="value">{{ $t('error.requiredFieldByType', { type: error.value }) }}</span>
            </div>
          </div>
          <div class="mt-2 add-broadcast">
            {{ $t('title') }}
            <AppInput
              color="var(--v-secondary-base)"
              :data-test-id="bcTitle"
              class="mt-1"
              :counter="100"
              :maxLength="100"
              v-model="title"
              @change="changeItem('title')"
              :error="inputHasError(selectedDestination.user, $t('title'))"
            />
          </div>
          <div v-if="selectedDestination.channel === 'Youtube'">
            {{ $t('description') }}
            <AppTextarea
              class="mt-1"
              :data-test-id="bcDescription"
              color="var(--v-secondary-base)"
              v-model="description"
              :counter="2000"
              :maxLength="2000"
              @change="changeItem('description')"
              :error="inputHasError(selectedDestination.user, $t('description'))"
            />
          </div>
          <div v-if="selectedDestination.channel === 'Youtube'">
            {{ $t('privacy') }}
            <AppSelect :data-test-id="youtubeSelect" v-model="privacy" class="mt-1" :items="items" @change="changeItem('privacy')" />
          </div>
          <div v-if="selectedDestination.channel === 'Twitch'">
            {{ $t('category') }}
            <AppSelect :data-test-id="twitchSelect" v-model="category" class="mt-1" :items="categories" @change="changeItem('category')" />
          </div>
        </div>
      </div>
      <div v-if="!broadcastItems">
        <v-divider v-if="filteredDestinations.length" class="my-2"></v-divider>
        <div>
          <AddDestination v-if="!filteredDestinations.length" trigger-element-type="button" />
          <span v-if="!broadcastSessionName || broadcastItems" class="ml-4 skip-for-now cursor-pointer opacity-50" @click="setWithoutDestination">
            {{ $t('broadcasts.skipForNow') }}
          </span>
        </div>
      </div>
      <div v-if="withoutDestination || sessionName" class="mt-4">
        <div class="mt-2 add-broadcast">
          {{ $t('sessionName') }}
          <AppInput v-model="sessionName" class="mt-1" :error="inputHasError('sessionName', $t('sessionName'))" />
        </div>
      </div>
      <div v-if="showCreateButton" class="d-flex align-center justify-center mt-4">
        <AppButton :data-test-id="bcCreate" class="create-broadcast" :class="{ 'w-full': withoutDestination }" @click="create" :loading="loading">
          <span class="opacity-50">{{ submitButtonText }}</span>
        </AppButton>
      </div>
      <div v-if="withoutDestination" class="opacity-50 mt-4 you-can-also-add">
        {{ $t('broadcasts.youCanAlsoAdd') }}
      </div>
    </div>
    <template v-else>
      <div>{{ $t('removeThisDestination') }}</div>
      <div class="d-flex align-center my-4">
        <DestinationAvatar :avatar="selectedForDelete.avatarUrl" :destination-types="selectedForDelete.providerShortCode" icon-position="right" />
        <span class="ml-3">{{ selectedForDelete.userProviderDetail.displayName }}</span>
      </div>
      <div>{{ $t('areYouSureToDeletePost', { itemName: selectedForDelete.providerShortCode }) }}</div>

      <div v-if="selectedForDelete.providerShortCode === 'Youtube'" class="d-flex">
        <v-checkbox v-model="deleteOnYoutube" :label="$t('broadcasts.deleteOnYoutube')" hide-details></v-checkbox>
      </div>
      <div class="d-flex align-center justify-start mt-4">
        <AppButton color="var(--v-error-base)" class="mr-2" :loading="deleteLoading" @click="deleteBroadcast">
          <span>{{ $t('broadcasts.deleteBroadcast') }}</span>
        </AppButton>
        <AppButton :disabled="deleteLoading" @click="close">
          <span>{{ $t('cancel') }}</span>
        </AppButton>
      </div>
    </template>
  </AppModal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { consoleError } from 'xpermeet-lib';
import { DESTINATIONS, NOTIFICATION } from '@/constants/modules';
import AddDestination from '@/components/AddDestination';
import { YOUTUBE_BROADCAST_PRIVACY_TYPES } from '@/constants/enums';
import { CATEGORIES } from '@/constants/twitchCategories';
import xssService from '@/services/xss.service';
import { TOAST_TYPE } from '@/constants/toast';
import DestinationAvatar from '@/components/DestinationAvatar';
import ExpiredTokenWarningVue from '@/components/ExpiredTokenWarning.vue';

export default {
  name: 'AddEditBroadcastModal',
  components: { AddDestination, DestinationAvatar, ExpiredTokenWarningVue },
  props: {
    broadcast: Object,
    isLive: Boolean,
    selectedDestinationOauthId: [Number, String],
    editOn: {
      type: String,
      default: 'dashboard',
    },
  },
  data() {
    return {
      dialog: false,
      title: '',
      description: '',
      sessionId: '',
      withoutDestination: false,
      loading: false,
      selectedDestinations: [],
      selectedDestination: {},
      form: {},
      sessionName: '',
      items: [
        {
          text: this.$t(`broadcasts.privacyTypes.${YOUTUBE_BROADCAST_PRIVACY_TYPES.PUBLIC}`),
          value: YOUTUBE_BROADCAST_PRIVACY_TYPES.PUBLIC,
        },
        {
          text: this.$t(`broadcasts.privacyTypes.${YOUTUBE_BROADCAST_PRIVACY_TYPES.UNLISTED}`),
          value: YOUTUBE_BROADCAST_PRIVACY_TYPES.UNLISTED,
        },
        {
          text: this.$t(`broadcasts.privacyTypes.${YOUTUBE_BROADCAST_PRIVACY_TYPES.PRIVATE}`),
          value: YOUTUBE_BROADCAST_PRIVACY_TYPES.PRIVATE,
        },
      ],
      privacy: YOUTUBE_BROADCAST_PRIVACY_TYPES.PUBLIC,
      categories: [...CATEGORIES],
      category: CATEGORIES[0].value,
      selectedForDelete: null,
      deleteOnYoutube: false,
      deleteLoading: false,
      errors: [],
    };
  },
  computed: {
    ...mapState(DESTINATIONS, ['destinations']),
    ...mapGetters(DESTINATIONS, ['getDestinationByUserProviderId', 'getDestinationById']),
    containExpiredToken() {
      return this.destinations.some((d) => !d.tokenIsValid);
    },
    showCreateButton() {
      return this.selectedDestinations.length || this.withoutDestination || this.sessionName;
    },
    isEdit() {
      return this.broadcastItems || this.broadcastRawItems?.sessionName;
    },
    submitButtonText() {
      return this.isEdit ? this.$t('broadcasts.updateBroadcast') : this.$t('broadcasts.createBroadcast');
    },
    broadcastItems() {
      return this.broadcastRawItems?.items?.length;
    },
    broadcastRawItems() {
      return this.broadcast?.raw || this.broadcast;
    },
    isLiveSession() {
      return this.broadcast?.status === 'live' || this.isLive;
    },
    filteredDestinations() {
      if (this.isLiveSession) {
        return this.destinations.length ? this.destinations.filter((destination) => this.selectedDestinations.includes(destination.id)) : this.destinations;
      } else {
        return this.destinations;
      }
    },
    anySelectedDestination() {
      return Object.keys(this.selectedDestination).length !== 0;
    },
    broadcastSessionName() {
      return this.broadcastRawItems?.sessionName;
    },
    isStudioLastProvider() {
      return this.isEdit && this?.broadcast?.items?.length === 1 && this.editOn === 'studio';
    },
    deletePayload() {
      if (!this.selectedForDelete) {
        return null;
      }
      let deleteList = [
        {
          userSessionProviderId: this.selectedForDelete.userSessionProviderId,
          deleteOnProvider: this.selectedForDelete.providerShortCode === 'Youtube' && this.deleteOnYoutube,
        },
      ];
      const data = {
        sessionId: this.sessionId,
      };
      if (this.isStudioLastProvider) {
        data.deleteProvider = deleteList[0];
        data.deleteProvider.deleteOnProvider = true;
      } else {
        data.deleteList = deleteList;
      }
      return data;
    },
    deleteMethod() {
      return !this.isStudioLastProvider ? 'deleteSession' : 'deleteProvider';
    },
    bcTitle() {
      return 'broadcast-title';
    },
    bcDescription() {
      return 'broadcast-description';
    },
    bcCreate() {
      return 'broadcast-create';
    },
    youtubeSelect() {
      return 'broadcast-youtube-privacy';
    },
    twitchSelect() {
      return 'broadcast-twitch-category';
    },
  },
  created() {
    if (this.isEdit) {
      this.setDefaultBroadcasts();
    }
  },
  methods: {
    ...mapActions(NOTIFICATION, ['showToastNotification']),
    async create() {
      try {
        let items = Object.values(this.form);
        this.errors = [];
        if (!this.destinations.length) {
          if (!this.sessionName) {
            this.errors.push({
              type: 'sessionName',
              value: this.$t('sessionName'),
            });
          }
          items = { sessionName: this.sessionName, items: [] };
        } else {
          this.checkRequiredFields(items);
        }
        if (!this.errors.length) {
          this.loading = true;
          if (!this.isEdit) {
            const payload = this.destinations.length ? { items } : items;
            if (this.sessionName) {
              payload.sessionName = this.sessionName;
            }
            const { data } = await xssService.prepareBroadcast(payload);
            this.$emit('completed', data.sessionId);
          } else {
            let data = {
              sessionId: this.sessionId,
            };
            if (items.length) {
              data.items = items;
            }
            if (this.sessionName) {
              data.sessionName = this.sessionName;
            }
            await xssService.updateSession(data);
            this.$emit('completed');
          }
        }
      } catch (err) {
        consoleError(err);
        this.showToastNotification({ body: 'somethingWentWrong', config: { type: TOAST_TYPE.ERROR } });
      } finally {
        this.loading = false;
      }
    },
    checkRequiredFields(items) {
      items.forEach((item) => {
        const destination = this.getDestinationById(item.userProviderId);
        if (!item.title) {
          this.errors.push({
            type: destination.user,
            value: this.$t('title'),
          });
        }
        if (destination.channel === 'Youtube') {
          if (!item.description) {
            this.errors.push({
              type: destination.user,
              value: this.$t('description'),
            });
          }
          if (!item.privacy) {
            this.errors.push({
              type: destination.user,
              value: this.$t('privacy'),
            });
          }
        }
      });
    },
    selectDestination(destination) {
      this.removeFields();
      const selectedBroadcast = this.getSelectedBroadcastProperties(destination);
      if (this.selectedDestinations.includes(destination.id)) {
        if (this.selectedDestination?.id === destination.id) {
          if (selectedBroadcast) {
            if (!this.isLiveSession) {
              this.$set(this, 'selectedForDelete', selectedBroadcast);
            }
          } else {
            delete this.form[destination.id];
            this.selectedDestinations = this.selectedDestinations.filter((id) => id !== destination.id);
            this.selectedDestination = this.selectedDestinations[0] || {};
            if (this.selectedDestinations?.length) {
              const lastSelectedDestination = this.destinations.find(
                (destination) => destination.id === this.selectedDestinations[this.selectedDestinations.length - 1],
              );
              this.setDestination(lastSelectedDestination);
            }
          }
        } else {
          this.setDestination(destination);
        }
      } else {
        this.selectedDestinations.push(destination.id);
        this.setDestination(destination);
        this.withoutDestination = false;
        this.sessionName = this.broadcastSessionName;
      }
      this.setFields(this.selectedDestination);
      if (selectedBroadcast) {
        const destination = this.getDestinationByUserProviderId(selectedBroadcast.userProviderId, selectedBroadcast.userProviderDetail.oauthId);
        this.title = this.form[destination.id].title;
        if (destination.channel === 'Youtube') {
          this.description = this.form[destination.id].description;
          this.privacy = this.form[destination.id].privacy;
        }
      }
    },
    changeItem(type) {
      this.form[this.selectedDestination.id][type] = this[type];
      this.errors = [];
    },
    removeFields() {
      this.title = '';
      this.description = '';
      this.privacy = YOUTUBE_BROADCAST_PRIVACY_TYPES.PUBLIC;
      this.category = CATEGORIES[0].value;
    },
    setFields(destination) {
      this.title = this.form[destination.id]?.title || destination.title || '';
      this.description = this.form[destination.id]?.description || destination.description || '';
      this.privacy = this.form[destination.id]?.privacy || destination.privacy || YOUTUBE_BROADCAST_PRIVACY_TYPES.PUBLIC;
      this.category = this.form[destination.id]?.category || destination?.category || CATEGORIES[0].value;
    },
    close() {
      this.removeFields();
      this.dialog = false;
      this.$modal?.close();
    },
    setDestination(destination) {
      this.selectedDestination = destination;
      const form = this.form[destination.id];
      const formObject = {
        userProviderId: destination.id,
      };
      formObject.title = form?.title || this.title;
      if (form?.userSessionProviderId) {
        formObject.userSessionProviderId = form.userSessionProviderId;
      }
      if (destination.channel === 'Youtube') {
        formObject.privacy = form?.privacy || YOUTUBE_BROADCAST_PRIVACY_TYPES.PUBLIC;
        formObject.description = form?.description || this.description;
      }
      if (destination.channel === 'Twitch' && !formObject.category) {
        formObject.category = this.category;
      }
      this.form[destination.id] = formObject;
    },
    getSelectedBroadcastProperties(destination) {
      return this.broadcastRawItems?.items.find((item) => item.userProviderDetail.oauthId === destination.raw.openAuthId);
    },
    setDefaultBroadcasts() {
      this.sessionId = this.broadcastRawItems.sessionId;
      const items = [...this.broadcastRawItems.items];
      if (this.broadcastRawItems?.sessionName) {
        this.sessionName = this.broadcastRawItems.sessionName;
      }
      if (items.length) {
        for (let item of items) {
          const selectedOauthId = item.userProviderDetail.oauthId;
          const destination = this.getDestinationByUserProviderId(item.userProviderId, selectedOauthId);
          this.selectedDestinations.push(destination.id);
          let setterObject = { title: item.title, userProviderId: destination.id, userSessionProviderId: item.userSessionProviderId };
          if (destination.channel === 'Youtube') {
            setterObject.description = item.description;
            setterObject.privacy = item.privacy;
          }
          if (destination.channel === 'Twitch') {
            setterObject.category = item.category;
          }
          this.$set(this.form, destination.id, setterObject);
        }
        let firstDestinationOfBroadcast = this.selectedDestinationOauthId
          ? items.find((item) => item.userProviderDetail.oauthId === this.selectedDestinationOauthId)
          : items[0];
        this.selectedDestination = this.destinations.find((destination) =>
          this.selectedDestinationOauthId
            ? destination.raw.openAuthId === this.selectedDestinationOauthId
            : destination.raw.openAuthId === items[0].userProviderDetail.oauthId,
        );
        if (!this.selectedDestination?.id) {
          this.selectedDestination.id = this.selectedDestinations[0];
        }
        this.setFields(firstDestinationOfBroadcast);
      }
    },
    async deleteBroadcast() {
      try {
        this.deleteLoading = true;
        await xssService[this.deleteMethod](this.deletePayload);
        this.$emit('completed');
      } catch (err) {
        consoleError(err);
      } finally {
        this.deleteLoading = true;
      }
    },
    inputHasError(type, value) {
      return this.errors.some((error) => error.type === type && error.value === value);
    },
    setWithoutDestination() {
      this.withoutDestination = true;
      this.selectedDestinations = [];
      this.selectedDestination = {};
      this.form = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.errors {
  .error-title {
    font-size: 16px;
    color: var(--v-error-darken2);
    font-weight: 600;
  }
  .type {
    font-size: 13px;
    font-weight: 400;
  }
  .value {
    font-size: 11px;
    font-weight: 300;
  }
}
.add-remove-destination {
  &.active {
    border: 2px solid var(--v-secondary-base);
  }
}
.skip-for-now {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}
.you-can-also-add {
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
}
</style>

