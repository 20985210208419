var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _vm.triggerElementType === "span"
                ? _c(
                    "span",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "add-destination",
                          attrs: {
                            "data-test-id": `dsh-dst-add-destination-${_vm.triggerElementType}`,
                          },
                        },
                        "span",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("destinations.addADestination")) +
                          " "
                      ),
                      _c("span", { staticClass: "ml-3" }, [_vm._v("+")]),
                    ]
                  )
                : _vm._e(),
              _vm.triggerElementType === "icon-button"
                ? _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "ma-2",
                          attrs: {
                            "data-test-id": "broadcast-add-button",
                            width: "36",
                            height: "36",
                            outlined: "",
                            fab: "",
                            dark: "",
                            color: "var(--v-primary-lighten4)",
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("AppIcon", { attrs: { size: "16", icon: "plus" } })],
                    1
                  )
                : _vm._e(),
              _vm.triggerElementType === "button"
                ? _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "add-destination-btn",
                          attrs: { dark: "" },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "span",
                        {
                          staticClass: "mr-1",
                          class: { "opacity-50": !_vm.bright },
                        },
                        [_vm._v("+")]
                      ),
                      _c("span", { class: { "opacity-50": !_vm.bright } }, [
                        _vm._v(_vm._s(_vm.$t("destinations.addADestination"))),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        { staticClass: "py-0" },
        _vm._l(_vm.destinationItems, function (item, index) {
          return _c(
            "v-list-item",
            {
              key: index,
              staticClass: "select-destination",
              on: {
                click: function ($event) {
                  return _vm.goProviderUrl(item)
                },
              },
            },
            [
              _c("v-list-item-title", [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("ProviderIcon", {
                      staticClass: "mr-4",
                      staticStyle: { width: "24px" },
                      attrs: {
                        provider: item,
                        alt: item,
                        "data-test-id": `dsh-dst-add-${item}`,
                      },
                    }),
                    _c("span", [_vm._v(_vm._s(item))]),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }