<template>
  <v-alert type="warning" dense text prominent style="margin: 0">
    <div>{{ $t('providersTokenExpired') }}</div>
    <div class="text-decoration-underline cursor-pointer" @click="goToDestinationPage">{{ $t('destinations.page') }}</div>
  </v-alert>
</template>

<script>
export default {
  name: 'ExpiredTokenWarning',
  methods: {
    goToDestinationPage() {
      this.$emit('clicked');
      this.$router.push('/dashboard/destinations');
    },
  },
};
</script>

