var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      attrs: {
        "show-back-button": !!_vm.selectedForDelete,
        title: _vm.$t("broadcasts.broadcastTo"),
        "max-width": !!_vm.selectedForDelete ? "450" : "650",
      },
      on: {
        close: _vm.close,
        back: function ($event) {
          _vm.selectedForDelete = null
        },
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _vm.containExpiredToken
        ? _c("ExpiredTokenWarningVue", {
            staticClass: "mb-2",
            on: { clicked: _vm.close },
          })
        : _vm._e(),
      !_vm.selectedForDelete
        ? _c("div", [
            _vm.filteredDestinations.length
              ? _c("div", [
                  _c(
                    "div",
                    [
                      _vm._l(
                        _vm.filteredDestinations,
                        function (destination, key) {
                          return _c(
                            "AppButton",
                            {
                              key: key,
                              staticClass: "add-remove-destination",
                              class: {
                                "ml-2": key !== 0,
                                "opacity-50":
                                  !_vm.selectedDestinations.includes(
                                    destination.id
                                  ),
                                active:
                                  _vm.selectedDestination.id === destination.id,
                              },
                              attrs: {
                                "data-test-id": `brd-brd-add-${key}`,
                                disabled: !destination.tokenIsValid,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.selectDestination(destination)
                                },
                              },
                            },
                            [
                              _c("ProviderIcon", {
                                staticClass: "mr-4",
                                staticStyle: { width: "24px" },
                                attrs: {
                                  provider: destination.channel,
                                  alt: "Youtube",
                                },
                              }),
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    class: {
                                      "opacity-50":
                                        !_vm.selectedDestinations.includes(
                                          destination.id
                                        ),
                                    },
                                  },
                                  [_vm._v(_vm._s(destination.user))]
                                ),
                                !destination.tokenIsValid
                                  ? _c("div", { staticClass: "warning-text" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("tokenExpired")) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          )
                        }
                      ),
                      !_vm.isLiveSession
                        ? _c("AddDestination", {
                            attrs: { "trigger-element-type": "icon-button" },
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm.selectedDestination.user
                    ? _c("div", [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("selected")) +
                              ": " +
                              _vm._s(_vm.selectedDestination.user)
                          ),
                        ]),
                        _vm.errors.length
                          ? _c(
                              "div",
                              { staticClass: "errors py-2" },
                              [
                                _c("div", { staticClass: "error-title" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("error.errors")) + " "
                                  ),
                                ]),
                                _vm._l(_vm.errors, function (error, key) {
                                  return _c("div", { key: key }, [
                                    _c("span", { staticClass: "type" }, [
                                      _vm._v(_vm._s(error.type) + " :"),
                                    ]),
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("error.requiredFieldByType", {
                                            type: error.value,
                                          })
                                        )
                                      ),
                                    ]),
                                  ])
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "mt-2 add-broadcast" },
                          [
                            _vm._v(" " + _vm._s(_vm.$t("title")) + " "),
                            _c("AppInput", {
                              staticClass: "mt-1",
                              attrs: {
                                color: "var(--v-secondary-base)",
                                "data-test-id": _vm.bcTitle,
                                counter: 100,
                                maxLength: 100,
                                error: _vm.inputHasError(
                                  _vm.selectedDestination.user,
                                  _vm.$t("title")
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.changeItem("title")
                                },
                              },
                              model: {
                                value: _vm.title,
                                callback: function ($$v) {
                                  _vm.title = $$v
                                },
                                expression: "title",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.selectedDestination.channel === "Youtube"
                          ? _c(
                              "div",
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("description")) + " "
                                ),
                                _c("AppTextarea", {
                                  staticClass: "mt-1",
                                  attrs: {
                                    "data-test-id": _vm.bcDescription,
                                    color: "var(--v-secondary-base)",
                                    counter: 2000,
                                    maxLength: 2000,
                                    error: _vm.inputHasError(
                                      _vm.selectedDestination.user,
                                      _vm.$t("description")
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeItem("description")
                                    },
                                  },
                                  model: {
                                    value: _vm.description,
                                    callback: function ($$v) {
                                      _vm.description = $$v
                                    },
                                    expression: "description",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.selectedDestination.channel === "Youtube"
                          ? _c(
                              "div",
                              [
                                _vm._v(" " + _vm._s(_vm.$t("privacy")) + " "),
                                _c("AppSelect", {
                                  staticClass: "mt-1",
                                  attrs: {
                                    "data-test-id": _vm.youtubeSelect,
                                    items: _vm.items,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeItem("privacy")
                                    },
                                  },
                                  model: {
                                    value: _vm.privacy,
                                    callback: function ($$v) {
                                      _vm.privacy = $$v
                                    },
                                    expression: "privacy",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.selectedDestination.channel === "Twitch"
                          ? _c(
                              "div",
                              [
                                _vm._v(" " + _vm._s(_vm.$t("category")) + " "),
                                _c("AppSelect", {
                                  staticClass: "mt-1",
                                  attrs: {
                                    "data-test-id": _vm.twitchSelect,
                                    items: _vm.categories,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeItem("category")
                                    },
                                  },
                                  model: {
                                    value: _vm.category,
                                    callback: function ($$v) {
                                      _vm.category = $$v
                                    },
                                    expression: "category",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            !_vm.broadcastItems
              ? _c(
                  "div",
                  [
                    _vm.filteredDestinations.length
                      ? _c("v-divider", { staticClass: "my-2" })
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        !_vm.filteredDestinations.length
                          ? _c("AddDestination", {
                              attrs: { "trigger-element-type": "button" },
                            })
                          : _vm._e(),
                        !_vm.broadcastSessionName || _vm.broadcastItems
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "ml-4 skip-for-now cursor-pointer opacity-50",
                                on: { click: _vm.setWithoutDestination },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("broadcasts.skipForNow")) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.withoutDestination || _vm.sessionName
              ? _c("div", { staticClass: "mt-4" }, [
                  _c(
                    "div",
                    { staticClass: "mt-2 add-broadcast" },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("sessionName")) + " "),
                      _c("AppInput", {
                        staticClass: "mt-1",
                        attrs: {
                          error: _vm.inputHasError(
                            "sessionName",
                            _vm.$t("sessionName")
                          ),
                        },
                        model: {
                          value: _vm.sessionName,
                          callback: function ($$v) {
                            _vm.sessionName = $$v
                          },
                          expression: "sessionName",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.showCreateButton
              ? _c(
                  "div",
                  { staticClass: "d-flex align-center justify-center mt-4" },
                  [
                    _c(
                      "AppButton",
                      {
                        staticClass: "create-broadcast",
                        class: { "w-full": _vm.withoutDestination },
                        attrs: {
                          "data-test-id": _vm.bcCreate,
                          loading: _vm.loading,
                        },
                        on: { click: _vm.create },
                      },
                      [
                        _c("span", { staticClass: "opacity-50" }, [
                          _vm._v(_vm._s(_vm.submitButtonText)),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.withoutDestination
              ? _c("div", { staticClass: "opacity-50 mt-4 you-can-also-add" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("broadcasts.youCanAlsoAdd")) + " "
                  ),
                ])
              : _vm._e(),
          ])
        : [
            _c("div", [_vm._v(_vm._s(_vm.$t("removeThisDestination")))]),
            _c(
              "div",
              { staticClass: "d-flex align-center my-4" },
              [
                _c("DestinationAvatar", {
                  attrs: {
                    avatar: _vm.selectedForDelete.avatarUrl,
                    "destination-types":
                      _vm.selectedForDelete.providerShortCode,
                    "icon-position": "right",
                  },
                }),
                _c("span", { staticClass: "ml-3" }, [
                  _vm._v(
                    _vm._s(_vm.selectedForDelete.userProviderDetail.displayName)
                  ),
                ]),
              ],
              1
            ),
            _c("div", [
              _vm._v(
                _vm._s(
                  _vm.$t("areYouSureToDeletePost", {
                    itemName: _vm.selectedForDelete.providerShortCode,
                  })
                )
              ),
            ]),
            _vm.selectedForDelete.providerShortCode === "Youtube"
              ? _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("v-checkbox", {
                      attrs: {
                        label: _vm.$t("broadcasts.deleteOnYoutube"),
                        "hide-details": "",
                      },
                      model: {
                        value: _vm.deleteOnYoutube,
                        callback: function ($$v) {
                          _vm.deleteOnYoutube = $$v
                        },
                        expression: "deleteOnYoutube",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "d-flex align-center justify-start mt-4" },
              [
                _c(
                  "AppButton",
                  {
                    staticClass: "mr-2",
                    attrs: {
                      color: "var(--v-error-base)",
                      loading: _vm.deleteLoading,
                    },
                    on: { click: _vm.deleteBroadcast },
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("broadcasts.deleteBroadcast"))),
                    ]),
                  ]
                ),
                _c(
                  "AppButton",
                  {
                    attrs: { disabled: _vm.deleteLoading },
                    on: { click: _vm.close },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.$t("cancel")))])]
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }